<template>
  <img
    class="bannerFoto"
    src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/cabeceraponentes.jpg"
    alt=""
  />
  <div class="cookies">
    <h1 class="cookies_titulo">POLÍTICA DE COOKIES</h1>
    <h2 class="hidden">Congreso Knotgroup</h2>
    <p>
      Bienvenido a la Política de Cookies de INSPIRIA. A continuación, puede
      encontrar información detallada de las cookies que utiliza el sitio web
      donde se encuentra.
    </p>
    <p>
      Mediante la aceptación de nuestra Política de Cookies, usted consiente la
      instalación de las siguientes cookies en su navegador.
    </p>

    <h3>
      <span
        >1) Definición y función de cookies y otras tecnologías similares</span
      >
    </h3>
    <p>
      Una cookie es un fichero que nuestra página web envía a su navegador o
      dispositivo (smartphone, tableta o televisión conectada) y es almacenada
      por éste. Nuestra Web utiliza cookies al igual que la mayoría de sitios en
      Internet, para almacenar información sobre su visita.
    </p>
    <p>
      Las cookies tienen distintas funciones, como permitirle navegar de forma
      eficiente, recordar sus preferencias y, por lo general, mejorar su
      experiencia de usuario.
    </p>

    <h3>
      <span>2) ¿Qué tipo de cookies existen y cómo las utiliza INSPIRIA?</span>
    </h3>
    <p>
      <span
        >Existen muchos tipos de cookies, por lo que es común clasificarlas en
        grupos. En INSPIRIA, de acuerdo con las cookies que utilizamos, podemos
        clasificarlas en función de su:</span
      >
    </p>
    <p>
      <span style="text-decoration: underline"><span>Duración:</span></span>
    </p>

    <ul>
      <li style="list-style-type: none">
        <ul>
          <li>
            <span
              >Cookies de sesión: son cookies que se eliminan de su navegador
              una vez lo cierra.</span
            >
          </li>
          <li>
            <span
              >Cookies persistentes: son cookies que permanecen en su
              dispositivo durante un periodo de tiempo establecido o hasta que
              las borre.</span
            >
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <span style="text-decoration: underline"><span>Finalidad:</span></span>
    </p>

    <ul>
      <li style="list-style-type: none">
        <ul>
          <li>
            <span
              >Cookies estrictamente necesarias: son aquellas que utiliza
              INSPIRIA necesarias para el funcionamiento de la página. No se
              necesita consentimiento expreso para su descarga, ya que sin ellas
              no podría funcionar el sitio web. Por ejemplo, son las cookies que
              permiten la reproducción de nuestros vídeos, que lo identifican
              como suscriptor de nuestros servicios, etc.</span
            >
          </li>
          <li>
            <span
              >Cookies basadas en preferencias: son cookies que sirven para
              mejorar la funcionalidad de la página y la prestación del
              servicio. Hacen que el sitio web recuerde sus preferencias
              seleccionadas con anterioridad, como, por ejemplo, su usuario y
              contraseña para el acceso a nuestros Servicios. La información que
              recopilan estas cookies es anónima y no se podrá realizar un
              seguimiento de su actividad de navegación en otros sitios
              web.</span
            >
          </li>
          <li>
            <span
              >Cookies de estadísticas: destinadas a mejorar el rendimiento de
              la página y nuestros servicios, mediante recopilación de datos
              relativos de cómo utiliza nuestra página, datos de rendimiento, el
              navegador y dispositivo que utiliza, el tiempo de sesión, el lugar
              desde donde se conecta (país y municipio), el número de visitas
              realizadas a la página previamente y su dirección IP anonimizada.
              También podemos obtener información de nuestros boletines de
              correo electrónico y de otras comunicaciones que le enviemos,
              incluso si ha abierto o enviado un boletín o ha hecho clic en su
              contenido. Esta información nos permite analizar la eficacia de
              nuestros envíos comerciales. Si ha nos ha cedido sus datos de
              usuario (nombre y correo electrónico), dichos datos podrán
              contrastarse con estas cookies, previo consentimiento
              expreso.</span
            >
          </li>
          <li>
            <span
              >Cookies de marketing y publicitarias: Estas cookies recopilan
              información acerca de sus hábitos de navegación con el fin de
              adecuar la publicidad a usted y a sus intereses. Por lo general,
              las utilizan las redes de publicidad con el permiso del operador
              del sitio web. Así, recuerdan que ha visitado un sitio web y
              comparte esta información con otras organizaciones dedicadas a la
              publicidad.</span
            >
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <span style="text-decoration: underline"><span>Origen:</span></span>
    </p>

    <ul>
      <li style="list-style-type: none">
        <ul>
          <li>
            <span
              >Cookies propias: son cookies establecidas en el sitio web donde
              se encuentra, ya sea por INSPIRIA o por un tercero al que INSPIRIA
              se lo haya solicitado.</span
            >
          </li>
          <li>
            <span
              >Cookies de terceros: son cookies establecidas por un tercero
              ajeno al sitio web. En INSPIRIA pueden encontrarse cookies de
              terceros de las siguientes entidades:</span
            >
            <ul>
              <li>
                <span
                  >Facebook -
                  <a href="https://www.facebook.com/"
                    >https://www.facebook.com/</a
                  ></span
                >
              </li>
              <li>
                <span
                  >Google -
                  <a href="https://www.google.com/"
                    >https://www.google.com/</a
                  ></span
                >
              </li>
              <li>
                <span
                  >Zoho -
                  <a href="https://www.zoho.com">https://www.zoho.com</a></span
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p>
      Si usted lo acepta, podemos permitir que dichas entidades recojan
      información de cookies para alguno de los fines explicados anteriormente,
      incluida la recopilación de información sobre sus actividades en línea con
      el paso del tiempo, y en distintos sitios web y dispositivos.
    </p>

    <h3>
      <span>3) ¿Cómo puedo gestionar mis preferencias de cookies?</span>
    </h3>
    <p style="display: inline-block">
      Una vez que has aceptado nuestra Política de Cookies, éstas permanecerán
      en tu navegador por determinado tiempo. Si has cambiado de idea y deseas
      gestionar las cookies de tu navegador, podrás elegir que cookies quieres
      que funcionen en este sitio web mediante:
    </p>

    <ul>
      <li style="list-style-type: none">
        <ul>
          <li>
            <span>La configuración del navegador, por ejemplo: </span>
            <ul>
              <li>
                <span
                  >Chrome, desde el siguiente
                  <a
                    href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647"
                    >enlace</a
                  ></span
                >
              </li>
              <li>
                <span
                  >Explorer, desde el siguiente
                  <a
                    href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
                    >enlace</a
                  ></span
                >
              </li>
              <li>
                <span
                  >Firefox, desde el siguiente
                  <a
                    href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
                    >enlace</a
                  ></span
                >
              </li>
              <li>
                <span
                  >Safari, desde el siguiente
                  <a href="http://support.apple.com/kb/ph5042"
                    >enlace</a
                  ></span
                >
              </li>
            </ul>
          </li>
          <li>
            <span
              >Existen herramientas de terceros, disponibles online, que
              permiten a los usuarios detectar las cookies en cada sitio web que
              visita y gestionar su desactivación.</span
            >
          </li>
        </ul>
      </li>
    </ul>
    <p style="display: inline-block">
      Tenga en cuenta que, si configura su navegador para rechazar las cookies,
      es posible que no pueda utilizar todas las funciones de este sitio web.
    </p>

    <h3>
      <span>4) Datos de contacto:</span>
    </h3>
    <p style="display: inline-block">
      Los datos de contacto de INSPIRIA para cualquier cuestión relacionada con
      la presente política son los siguientes:
    </p>

    <ul>
      <li style="list-style-type: none">
        <ul>
          <li>
            <span
              >Correo electrónico:
              <a href="mailto:hablamos@inspiriadental.com"
                >hablamos@inspiriadental.com</a
              ></span
            >
          </li>
          <li>
            <span
              >Teléfono: <a href="tel:+34918552767">(+34)91 855 27 67</a></span
            >
          </li>
          <li>
            <span
              >Dirección de contacto: C/ Mayor de Fontenebro 238, 28400, Collado
              Villalba, Madrid, España.</span
            >
          </li>
        </ul>
      </li>
    </ul>
    <h4 class="hidden">Knotgroup Dental Corporation</h4>
    <h5 class="hidden">Inspiria Dental</h5>
  </div>
</template>

<script>
</script>