<template>
  <div id="footer" class="footer_internas">
    <picture>
      <source
      class="footer_internas__logo"
      srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/logo-home-knotgroup.webp"
      type="image/webp"
      alt="Logo Congreso Knotgroup III Los Orígenes"
      title="Logo Congreso Knotgroup III Los Orígenes">
      <img
      class="footer_internas__logo"
      src="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/logo-home-knotgroup.png"
      alt="Logo Congreso Knotgroup III Los Orígenes"
      title="Logo Congreso Knotgroup III Los Orígenes"
      />
    </picture>
    <p class="footer_internas__p">
      Knotgroup<br>
      <strong>Oral Medicine,<br>
      inside.</strong>
    </p>
    <div class="last">
      <p class="footer_internas__privacidad">
        <a class="footer_internas__privacidad--a" href="/cookies" title="Política de cookies del Congreso Knotgroup">Política de Cookies</a>
        <a class="footer_internas__privacidad--a" href="/politica-de-privacidad" title="Política de privacidad del Congreso Knotgroup">Política de privacidad</a>
      </p>
      <div class="footer_internas__rrss">
        <a href="https://www.facebook.com/KnotgroupDentalCorporation" target="_blank" rel="noopener noreferrer" title="Facebook de Knotgroup Dental Corporation">
          <picture>
            <source srcset="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/footer/webp/icon-face.webp" type="image/webp">
            <img src="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/footer/png/icon-face.png" alt="Facebook de Knotgroup Dental Corporation" title="Facebook de Knotgroup Dental Corporation" loading="lazy">
          </picture>
        </a>
        <a href="https://www.instagram.com/KnotgroupDentalCorporation" target="_blank" rel="noopener noreferrer" title="Instagram de Knotgroup Dental Corporation">
          <picture>
            <source srcset="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/footer/webp/icon-ig.webp" type="image/webp">
            <img src="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/footer/png/icon-ig.png" alt="Instagram de Knotgroup Dental Corporation" title="Instagram de Knotgroup Dental Corporation" loading="lazy">
          </picture>
        </a>
      </div>
    </div>
  </div>
</template>
