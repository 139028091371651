<template>
  <section id="bloqueHome">
    <div class="contenidoHome">
      <div class="contenidoHome__contenedorIzq">
        <div class="filaUno">
          <picture>
            <source 
            class="imgLogoHome"
            srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/logo-home-knotgroup.webp"
            alt="Imagen Fondo  Knotgroup Los Orígenes"
            title="Imagen Fondo  Knotgroup Los Orígenes"
            type="image/webp"
            loading="lazy"
            >
            <img
              class="imgLogoHome"
              src="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/logo-home-knotgroup.png"
              alt="Imagen Fondo  Knotgroup Los Orígenes"
              title="Imagen Fondo  Knotgroup Los Orígenes"
              loading="lazy"
            />
          </picture>
        </div>
        <div class="filaDos">
          <h1 class="tituloIzq">
            <span>Estás a un paso de descubrir el</span> III Congreso Knotgroup <br />
            "Los Orígenes"
          </h1>
          <h2 class="ocultar">Congreso Odontología y Medicina Vigo</h2>
        </div>
        <div class="filaTres">
          <a class="ajusteenlace" title="programa Knotgroup" href="/programa">
            <picture>
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/icono-programa-knotgroup.png" type="image/png"> 
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-programa-knotgroup.webp" type="image/webp">
              <img class="imgLogos" src="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-programa-knotgroup.webp" title="Icono Programa Knotgroup Los Orígenes" alt="Icono Programa Knotgroup Los Orígenes" loading="lazy">
            </picture>
            <p class="tituloIcono">VER PROGRAMA</p>
          </a>
          <a class="ajusteenlace" title="ponentes Knotgroup" href="/ponentes">
            <picture>
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/icono-ponentes-knotgroup.png" type="image/png"> 
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-ponentes-knotgroup.webp" type="image/webp">
              <img class="imgLogos" src="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-ponentes-knotgroup.webp" title="Icono Ponentes Knotgroup Los Orígenes" alt="Icono Ponentes Knotgroup Los Orígenes" loading="lazy">
            </picture>
            <p class="tituloIcono">VER PONENTES</p>
          </a>
        </div>
        <div class="filaCuatro">
          <a class="ajusteenlace" href="/informacion/#ancla_sede">
            <picture>
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/icono-sedes-knotgroup.png" type="image/png"> 
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-sedes-knotgroup.webp" type="image/webp">
              <img class="imgLogos" src="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-sedes-knotgroup.webp" title="Icono Sedes Knotgroup Los Orígenes" alt="Icono Sedes Knotgroup Los Orígenes" loading="lazy">
            </picture>
            <p class="tituloIcono">SEDE</p>
          </a>
          <!-- <div class="home_vigo"> -->      
            <picture>
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/logo-vigo-knotgroup.png" type="image/png"> 
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/logo-vigo-knotgroup.webp" type="image/webp">
              <img class="filaCuatro__imgLogo" src="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/logo-vigo-knotgroup.webp" title="Logo Vigo Knotgroup Los Orígenes" alt="Logo Vigo Knotgroup Los Orígenes" loading="lazy">
            </picture>
            <div class="filaCuatro__rrss">
              <a href="https://www.facebook.com/KnotgroupDentalCorporation" target="_blank" rel="noopener noreferrer" title="Facebook de Knotgroup Dental Corporation">
                <picture>
                  <source srcset="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/footer/webp/icon-face.webp" type="image/webp">
                  <img src="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/footer/png/icon-face.png" alt="Facebook de Knotgroup Dental Corporation" title="Facebook de Knotgroup Dental Corporation" loading="lazy">
                </picture>
              </a>
              <a href="https://www.instagram.com/KnotgroupDentalCorporation" target="_blank" rel="noopener noreferrer" title="Instagram de Knotgroup Dental Corporation">
                <picture>
                  <source srcset="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/footer/webp/icon-ig.webp" type="image/webp">
                  <img src="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/footer/png/icon-ig.png" alt="Instagram de Knotgroup Dental Corporation" title="Facebook de Knotgroup Dental Corporation" loading="lazy">
                </picture>
              </a>

            </div>
         
          <!-- </div> -->
        </div>

        <div class="filaCinco">
          <picture>
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/icono-sponsors-knotgroup.png" type="image/png"> 
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-sponsors-knotgroup.webp" type="image/webp">
              <img class="imgLogos" src="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-sponsors-knotgroup.webp" title="Icono Sponsors Knotgroup Los Orígenes" alt="Icono Sponsors Knotgroup Los Orígenes" loading="lazy">
            </picture>
          <div class="cajaTexto">
            <div class="txtIcono">
              <h3 class="tituloIcono">SPONSORS</h3>
            </div>
            <div class="cajaTexto__flecha">
              <picture>
                <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/icono-flecha-knotgroup.png" type="image/png"> 
                <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-flecha-knotgroup.webp" type="image/webp">
              <img class="flechaBlanca" src="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/icono-flecha-knotgroup.webp" title="Icono Flecha Knotgroup Los Orígenes" alt="Icono Flecha Knotgroup Los Orígenes" loading="lazy">
            </picture>
         
            </div>
          </div>
        </div>
      </div>
      <div class="contenidoHome__contenedorDrcha">
        <div class="textoDerecha">
          <strong class="textoDerecha__texto">
            Odontología, Medicina, Digitalización, Tecnología, Equipos, Paciente, Nuestra
            Razón de Ser.
          </strong>
          <p class="textoDerecha__subtexto">
            Inscríbete al Congreso, cuéntanos si nos acompañaras a la cena y si
            necesitas desplazamiento.
          </p>
        </div>
        <!-- Formulario de zoho con nuestros estilos 
        @submit.prevent="validarDatos()"
         
        -->
        <form
          action="https://forms.zohopublic.com/inspiriadental/form/congresokg2023/formperma/n2-WmrWwefS1zR_869k0ZtyoE7j1ijcisZH4uVgwMxE/htmlRecords/submit"
          name="form"
          id="form"
          method="POST"
          accept-charset="UTF-8"
          enctype="multipart/form-data"
          class="formularioContacto"
        >
          <input
            type="hidden"
            name="zf_referrer_name"
            value=""
          />
          <input
            type="hidden"
            name="zf_redirect_url"
            value=""
          />
          <input
            type="hidden"
            name="zc_gad"
            value=""
          />
          <div class="inputNomApes">
            <input
              type="text"
              name="SingleLine"
              fieldType="1"
              v-model="nombreApellidos"
              class="sinFondo"
              placeholder="Nombre y apellidos *"
              required
              pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
              title="Inserte su nombre y apellidos. Ejemplo: [María Casas Pérez]"
            />
          </div>
       
          <div class="inputEmail">
            <input
              type="text"
              name="SingleLine2"
              fieldType="1"
              maxlength="255"
              v-model="email"
              class="sinFondo"
              placeholder="Email *"
              required
              pattern="^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
              title="Inserte un correo electrónico válido (Ej.: prueba@gmail.com)"
            />
          </div>
          <div class="juntos">
          
            <div class="inputTelf">
              <input
                type="text"
                name="SingleLine1"
                fieldType="1"
                v-model="telefono"
                class="sinFondo mitadTelf"
                placeholder="Teléfono personal *"
                required
                pattern="[0-9]{9}"
                title="Inserte un número de teléfono válido (Ej.: 430543528)"
              />
            </div>
         
          <div class="inputClinica">
              <input
                type="text"
                name="SingleLine3"
                fieldType="1"
                v-model="nombreClinica"
                class="sinFondo mitadClinica"
                placeholder="Clínica *"
                required
                pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1.]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1.]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1.]+$"
                title="Inserte el nombre de su clínica"
              />
            </div>
          </div>   
          <p>¿Eres socio/a de <strong>Knotgroup</strong>?*</p>
          <div class="socio">
            <input
              type="radio"
              id="socio"
              class="btnSocio"
              name="Radio"
              v-model="socioHome"
              value="Si"
              checked
            />
            <label for="socio">SOY SOCIO</label>

            <input
              type="radio"
              id="no_socio"
              class="btnSocio"
              v-model="socioHome"
              name="Radio"
              value="No"
            />
            <label for="no_socio">NO SOY SOCIO</label>

            <!-- <div>socio: {{ socioHome }}</div> -->
          </div>
          <!--Single Line -->
          <div>
            <input
              type="hidden"
              name="SingleLine4"
              value="presencial"
              fieldType="1"
            />
          </div>
          <p>Selecciona una opción:*</p>
          <!--Decision Box-->
          <div class="checkboxCena">
            <div class="checkytexto">
              <!-- soloCongreso(this) -->
              <input
                type="checkbox"
                id="DecisionBox"
                name="DecisionBox"
              
                class="checkbox-square"
                :checked="required.value == 1 && required.check"
                @click="[required.check = $event.target.checked,required.value = 1]"
              />
              <label class="textocena">Congreso</label>
            </div>
            <div class="precio">
              <label v-html="precio.congreso" class="textocena" for="DecisionBox"></label>
            </div>
          </div>

          <!--Decision Box-->
          <div class="checkboxCena">
            <div class="checkytexto">
              <!-- solocena(this) -->
              <input
                type="checkbox"
                id="DecisionBox3"
                name="DecisionBox3"
               
                class="checkbox-square"
                :checked="required.value == 2 && required.check"
                @click="[required.check = $event.target.checked,required.value = 2]"
              />
              <label class="textocena">Congreso + Cena</label>
            </div>
            <div class="precio">
              <label  v-html="precio.congresoCena" class="textocena" for="DecisionBox3"></label>

            <!-- <label class="textocena" for="DecisionBox3">{{
                precio.congresoCena
              }}</label> -->
            </div>
          </div>
          <!--Decision Box-->
          <!-- cenaytransporte(this)
           :required="required === 3"
                :disabled="required === 3"
          -->
          <div class="checkboxCena">
            <div class="checkytexto">
              <input
                type="checkbox"
                id="DecisionBox1"
                name="DecisionBox1"
               
                class="checkbox-square"
                :checked="required.value == 3 && required.check"
                @click="[required.check = $event.target.checked,required.value = 3]"
                
              />
              <label class="textocena"
                >Congreso + Cena + Desplazamiento a la cena</label
              >
            </div>
            <div class="precio">
              <label v-html=" precio.congresoCenaDespl" class="textocena" for="DecisionBox1"></label>
            </div>
          </div>
        <div class="checkboxCena">
          <p class="descuento_no_socios">Descuento solo hasta el 24 de marzo</p>
        </div>
          <div v-show="!required.check" class="errorCheck">
            Se requiere pulsar una de las tres opciones
          </div>
          <!-- <div>opciones: {{ opciones }}</div> -->

          <!--Decision Box-->
          <div class="cuentaIBAN">
            <p>IBAN ES45 0081 1392 2100 0116 3220</p>
          </div>

          <div class="checkboxTextosLegales">
            <input
              type="checkbox"
              id="DecisionBox2"
              class="checkbox-round"
              name="DecisionBox2"
              required
            />
            <label for="DecisionBox2"
              >He leido y acepto la
              <a class="enlacepolitica" title="politica de privacidad" href="/politica-de-privacidad"
                >política de privacidad
              </a>
              y
              <a class="enlacecookies" title="protección de datos" href="/cookies">
                protección de datos
              </a></label
            >
          </div>

          <div class="botonIncribete">
            <button
              :disabled="!required.check"
              type="submit"
              class="btnSubmit"
              value="Submit"  
              
               
            >
            <!-- @click="eliminarDisabled()" -->
              INSCRÍBETE
            </button>
          </div>
        </form>
        <!-- Fin de formulario de zoho con nuestros estilos -->

        <div class="cajaSponsors">
          <div class="imgSponsor">
            <picture>
              <img
                src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/ico-sponsors.png"
                alt="Icono Knotgroup sponsors"
                title="Logo III congreso Knotgroup Los Orígenes"
              />
            </picture>
          </div>
          <div class="cajaTexto">
            <div class="txtIcono">
              <h3 class="tituloIcono">SPONSORS</h3>
            </div>
            <div class="cajaTexto__flecha">
              <img
                class="flechaBlanca"
                src="https://d5uuf868kzhnu.cloudfront.net/wp-content/uploads/2021/12/14101236/flecha_pc.png"
                alt="Flecha Blanca Knotgroup"
                title="Logo III congreso Knotgroup Los Orígenes"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 class="hidden">Patrocinadores Congreso</h3>
    <h4 class="hidden">Normon Dental</h4>
    <h4 class="hidden">EMS</h4>
    <h4 class="hidden">Teethan</h4>
    <h4 class="hidden">Bioner</h4>
    <h4 class="hidden">Createch</h4>
    <h4 class="hidden">Nobel Biocare</h4>
    <h4 class="hidden">Dentista Moderno</h4>
    <h5 class="hidden">Odontología</h5>
    <h5 class="hidden">Medicina</h5>
    <h5 class="hidden">Digitalización</h5>
    <h5 class="hidden">Tecnología</h5>
    <h5 class="hidden">Equipos</h5>
    <h5 class="hidden">Paciente</h5>
    <h6 class="hidden">Vigo Congreso</h6>
  </section>
</template>

<script>
//import axios from "axios";

export default {
  name: "ContenidoHome",
  props: ["form"],
  data: function () {
    return {
      nombreApellidos: "",
      email: "",
      telefono: "",
      nombreClinica: "",
      congreso: "No marcado",
      cena: "No marcado",
      cenayTransporte: "No marcado",
      socioHome: "si",
      // opciones: "gratis",
      //Para cambiar visualmente los precios
      formulario: this.form,
      precio: this.form.socios,
      required: {
        value: 1,
        check:true
      },
    };
  },
  watch: {
    socioHome: function (value, oldeValue) {
      if (value === "Si") {
        this.precio = this.formulario.socios;
        document.querySelector(".descuento_no_socios").style.display = 'none';
      } else {
        this.precio = this.formulario.noSocios;
        document.querySelector(".descuento_no_socios").style.display = 'flex';
      }
    },
  }
};
</script>