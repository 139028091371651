<template id="informacionBloque">
  <section class="informacionBloque">
    <h1 class="hidden">Congreso Knotgroup</h1>
    <section class="informacion__imagen">
      <picture class="picture"> 
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/informacion/webp/cabecera-knotgroup.webp" type="image/webp">
              <img class="informacion__imagen__img" src="https://congresoknotgroup.inspiriadental.com/imagenes/informacion/jpg/cabecera-informacion-knotgroup.jpg" title="Cabecera informacion Knotgroup Los Orígenes" alt="Cabecera informacion Knotgroup Los Orígenes" loading="lazy">
      </picture>
    </section>

    <section class="informacion__origenes">
      <h2 class="informacion__origenes__h2">{{ losOrigenes.titulo }}</h2>
      <p
        v-for="info in losOrigenes.parrafos"
        :key="info"
        class="informacion__origenes__p"
      >
        {{ info }}
      </p>
    </section>
    <section id="ancla_comite" class="informacion__comite">
      <h2 class="informacion__comite__h2">{{ comiteCientifico.titulo }}</h2>
      <div class="informacion__comite__contenedor">
        <div
          v-for="cientificos in comiteCientifico.organizadores"
          :key="cientificos"
          class="informacion__comite__grid"
        >

        <picture class="picture"> 
              <source :srcset="cientificos.img.urlw" type="image/webp">
              <img class="informacion__imagen__img" :src="cientificos.img.url" :alt="cientificos.img.alt" :title="cientificos.img.title" loading="lazy">
      </picture>

          <div class="textos">
            <img
              class="textos__fondo"
              loading="lazy"
              src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/name.jpg"
            />
            <p class="textos__nombre">{{ cientificos.nombre }}</p>
            <p class="textos__titulo" v-if="cientificos.titulo != ''">
              {{ cientificos.titulo }}
            </p>
          </div>
        </div>
      </div>
      <h2 class="informacion__comite__h2">{{ comite.titulo }}</h2>
      <div class="informacion__comite__contenedor">
        <div
          v-for="organizadores in comite.organizadores"
          :key="organizadores"
          class="informacion__comite__grid"
        >
        
        <picture class="picture"> 
              <source :srcset="organizadores.img.urlw" type="image/webp">
              <img class="informacion__imagen__img" :src="organizadores.img.url" :alt="organizadores.img.alt" :title="organizadores.img.title" loading="lazy">
      </picture>
          <div class="textos">
            <picture class="picture">
              <source
                srcset="https://congresoknotgroup.inspiriadental.com/imagenes/ponentes/webp/imagen-fondo-nombre-knotgroup.webp"
                class="textos__fondo"
                type="image/webp"
                alt="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
                title="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
                loading="lazy">
              <img
                class="textos__fondo"
                src="https://congresoknotgroup.inspiriadental.com/imagenes/ponentes/jpg/imagen-fondo-nombre-knotgroup.jpg"
                alt="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
                title="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
                loading="lazy"
              />
            </picture>
            <p class="textos__nombre">{{ organizadores.nombre }}</p>
            <p class="textos__titulo" v-if="organizadores.titulo != ''">
              {{ organizadores.titulo }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="ancla_sede" class="informacion__sede">
      <h2 class="informacion__sede__h2">Sede</h2>
      <p class="informacion__sede__p">{{ sede.texto }}</p>
      <p class="informacion__sede__p">{{ sede.fecha }}</p>

      <picture class="picture picture__block">
              <source :srcset="sede.img.urlw" type="image/webp">
              <img class="informacion__imagen__img" :src="sede.img.url" :alt="sede.img.alt" :title="sede.img.title" loading="lazy">
      </picture>
     
      <!-- quitar esto y descomentar lo de arriba -->
    </section>
    <section id="ancla_vigo" class="informacion__vigo">
      <h2 class="informacion__vigo__h2">Vigo</h2>
      <div class="informacion__vigo__bloque">
      <picture class="picture picture__block">
              <source :srcset="vigo.imgs[0].urlw" type="image/webp">
              <img class="informacion__imagen__img" :src="vigo.imgs[0].url" :alt="vigo.imgs[0].alt" :title="vigo.imgs[0].title" loading="lazy">
      </picture>
      </div>

      <div id="ancla_cena" v-for="info in vigo.descripciones" :key="info">
        <p class="informacion__vigo__p">{{ info }}</p>
      </div>
    </section>
    <section class="informacion__cena">
      <h2 class="informacion__cena__h2">Cena</h2>
      <p class="informacion__cena__p">Restaurante</p>
      <p class="informacion__cena__p">{{ cena.restaurante }}</p>
      <p class="informacion__cena__p informacion__cena__p--direccion">
        {{ cena.direccion }}
      </p>
      <p class="informacion__cena__p informacion__cena__p--direccion">
        {{ cena.descripcion }}
      </p>
      <div class="informacion__cena__contenedor">
        <picture class="picture picture__block"> 
              <source :srcset="cena.img.urlw" type="image/webp">
              <img class="informacion__imagen__img" :src="cena.img.url" :alt="cena.img.alt" :title="cena.img.title" loading="lazy">
      </picture>
      </div>
    </section>
    <section id="ancla_descuentos" class="informacion__descuentos">
      <h2 class="informacion__descuentos__h2">Descuentos en transporte</h2>

      <div v-for="descuento in descuentos" :key="descuento" class="contenedor">
       
  
        <!-- <source :srcset="descuento.logo.url" type="image/png"> 
        <source :srcset="descuento.logo.urlw" type="image/webp"> -->
        <img :src="descuento.logo.url" :alt="descuento.logo.alt" :title="descuento.logo.title" loading="lazy"/>
        <div class="enlaces" v-if="descuento.enlaces != null">
          <p class="informacion__descuentos__p">{{ descuento.texto }}</p>

          <div
            v-for="enlace in descuento.enlaces"
            :key="enlace"
            class="enlaces__link"
          >
            <span class="informacion__descuentos__p">{{
              enlace.tituloEnlace
            }}</span>
            <a :href="enlace.linkEnlace">{{ enlace.textoEnlace }}</a>
          </div>
        </div>
        <p v-else class="informacion__descuentos__p">{{ descuento.texto }}</p>
      </div>

      <h2 class="informacion__descuentos__h2">Hoteles</h2>
      <!-- <div v-for="descuentoH in descuentosHoteles" :key="descuentoH" class="contenedor">
        <img :src="descuentoH.logo" />
        <p class="informacion__descuentos__p">{{ descuentoH.texto }}</p>
      </div> -->
      <div
        v-for="descuentoH in descuentosHoteles"
        :key="descuentoH"
        class="contenedor"
      >
<!--         
        <source :srcset="descuentoH.logo.url" type="image/png"> 
        <source :srcset="descuentoH.logo.urlw" type="image/webp"> -->
        <img :src="descuentoH.logo.url" :alt="descuentoH.logo.alt" :title="descuentoH.logo.title" loading="lazy"/>
        
        <div class="informacion__descuentos__div">
          <h3 class="informacion__descuentos__h3">{{ descuentoH.titulo }}</h3>
          <p class="informacion__descuentos__p">{{ descuentoH.condiciones }}</p>
          <ul class="informacion__descuentos__ul">
            <li
              class="informacion__descuentos__li"
              v-for="descuentoHprecios in descuentoH.precios"
              :key="descuentoHprecios"
            >
              <p>{{ descuentoHprecios }}</p>
            </li>
          </ul>
          <p
            class="informacion__descuentos__p"
            v-for="descuentoHtextos in descuentoH.textos"
            :key="descuentoHtextos"
          >
            {{ descuentoHtextos }}
          </p>
        </div>
      </div>
    </section>
    <section class="informacion__programaSocios">
      <h2 class="informacion__programaSocios__h2">
        {{ socios.titulo }}
      </h2>

      <div class="informacion__programaSocios__contenido" v-for="enlace in socios.enlaces"
            :key="enlace"
            >
        <p class="informacion__programaSocios__p">
          {{
              enlace.descripcion
            }}
        </p>
        <div class="informacion__programaSocios__link">
          <span class="informacion__programaSocios__p">{{ enlace.tituloEnlace }}</span
          ><a
            :href="enlace.linkEnlace"
            >{{ enlace.textoEnlace }}</a

          >
        </div>
      </div>
    </section>
    <h3 class="hidden">Odontología</h3>
    <h3 class="hidden">Medicina</h3>
    <h4 class="hidden">Knotgroup Dental Corporation</h4>
    <h5 class="hidden">Los Orígenes</h5>
    <h6 class="hidden">Clinica Dental Barberá</h6>
    <h6 class="hidden">Inspiria Dental</h6>
  </section>
</template>




<script>
import { mapState } from "vuex";
export default {
  name: "informacion principal",
  props: {
    informacion: Object,
  },
  setup(props) {
    const informacion = props.informacion;
    return { informacion };
  },
  computed: {
    ...mapState("cambioImagenes", ["infoUsuario"]),
  },
  data() {
    return {
      losOrigenes: this.informacion.bloque_los_origenes,
      comite: this.informacion.bloque_comite_organizador,
      sede: this.informacion.bloque_sede,
      vigo: this.informacion.bloque_vigo,
      cena: this.informacion.bloque_cena,
      descuentos: this.informacion.bloque_descuentos.descuentos,
      descuentosHoteles: this.informacion.bloque_descuentos.descuentos_hoteles,
      comiteCientifico: this.informacion.bloque_comite_cientifico,
      socios: this.informacion.bloque_socios,
    };
  },
  methods: {
    scrollIntoFrom() {
      if (
        document.referrer != "/informacion" &&
        (window.location.href.includes("ancla_cena") ||
          window.location.href.includes("ancla_sede") ||
          window.location.href.includes("ancla_comite") ||
          window.location.href.includes("ancla_vigo") ||
          window.location.href.includes("ancla_descuentos"))
      ) {
        setTimeout(() => {
          document
            .querySelector(window.location.hash)
            .scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    },
  },
  mounted() {
    this.scrollIntoFrom();
  },
};
</script>