<template>
  <section v-if="error">
    <Error />
  </section>
  <section v-if="loading">
    <Loading />
  </section>
  <section v-else id="PaginaGracias">
    <h1 class="hidden">Gracias Congreso Knotgroup</h1>
    <h2 class="hidden">Los Orígenes Congreso</h2>
    <h3 class="hidden">Congreso Vigo</h3>
    <div class="paginaGracias__contenido">
      <div class="paginaGracias__img">
        <picture>
          <img
            class="imgLogoHome"
            src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/logo-home.png"
            alt=""
          />
        </picture>
      </div>
      <div class="contenidoGracias">
        <p class="parrafo1">Gracias por inscribirte.</p>
        <p class="parrafo2">Recibirás un email de confirmación.</p>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'


import Loading from "@/components/loading/Loading.vue";
import Error from "@/components/error/Error.vue";

export default {
  name: "PaginaGracias",
  components: {
    Loading,
    Error
  },
  mounted() {
   this.redireccionHome();
  },
  methods: {
    redireccionHome() {
        setTimeout(function(){ window.location = "/informacion"},8000);
    }
  },
};
</script>
    