import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import Ponentes from '../views/Ponentes'
import Programa from '../views/Programa'
import Privacidad from '../views/Privacidad'
import Cookies from '../views/Cookies'
import Informacion from "../views/Informacion"
import ComplementoPrivacidad from "../views/ComplementoPrivacidad"
import DetellesPonente from "../views/DetallesPonenete"
import Talleres from '../views/Talleres'
import notFound from '../views/notFound.vue'
import Gracias from '../views/PaginaGracias.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Home',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'Knotgroup, congreso odontología, Congreso Knotgroup, Knotgroup Dental Corporation, congreso Vigo, odontología, medicina, congreso medicina'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/ponentes',
    name: 'ponentes',
    component: Ponentes,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Ponentes',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'ponentes congreso, doctores, ponencia, Knotgroup, Dr. Pedro Guitián, congreso Knotgroup, odontología'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/ponentes/:slug',
    name: 'detalles ponente',
    component: DetellesPonente,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Información Ponente',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'ponentes congreso, doctores, ponencia, Knotgroup, Dr. Pedro Guitián, congreso Knotgroup, odontología'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/programa',
    name: 'programa',
    component: Programa,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Programa',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'programa Congreso Knotgroup, Knotgroup, Congreso Knotgroup, patología oral, tratamientos dentales, odontólogos, congreso odontología'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/informacion',
    name: 'informacion',
    component: Informacion,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - información',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'congreso Vigo, Odontología y Medicina, Los Orígenes, Knotgroup, Pedro Guitián, Congreso Pontevedra, información Congreso Knotgroup, Congreso Knotgroup'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/politica-de-privacidad',
    name: 'politica de privacidad',
    component: Privacidad,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Política de privacidad',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'odontología, odontólogos, congreso odontología, Vigo, Knotgroup, Congreso Knotgroup, Knotgroup Dental Corporation, clínicas dentales'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: Cookies,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Cookies',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'odontología, odontólogos, congreso odontología, Vigo, Knotgroup, Congreso Knotgroup, Knotgroup Dental Corporation, clínicas dentales'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/complemento-privacidad',
    name: 'complemento privacidad',
    component: ComplementoPrivacidad,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Complemento privacidad',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'odontología, odontólogos, congreso odontología, Vigo, Knotgroup, Congreso Knotgroup, Knotgroup Dental Corporation, clínicas dentales'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/talleres',
    name: 'Talleres',
    component: Talleres,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Talleres',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'talleres congreso, Congreso Knotgroup, Knotgroup, salud periodontal, fotografía dental, taller dental, odontología, taller odontológico'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path:'/:pathMatch(.*)*',
    component: notFound
  },
  {
    path: '/gracias',
    name: 'PaginaGracias',
    component: Gracias,
    meta: {
      title: 'III Congreso Knotgroup Los Orígenes - Gracias',
      metaTags: [
        {
          name: 'description',
          content: 'Inscripciones abiertas. Ponentes internacionales y especialistas de prestigio hablarán sobre la relación de la Odontología y la Medicina. En Vigo el próximo 27 de mayo. ¡Te esperamos!'
        },
        {
          name: 'keywords',
          content: 'odontología, odontólogos, congreso odontología, Vigo, Knotgroup, Congreso Knotgroup, Knotgroup Dental Corporation, clínicas dentales'
        },
        {
          name: 'author',
          content: 'Knotgroup'
        },
        {
          name: 'publisher',
          content: 'Knotgroup'
        }
      ]
    }
  },
  {
    path: '/',
    redirect: {
      name: "home"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  // Borramos todas las etiquetas meta que son de la antigua pagina
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  /* Comprobamos que tiene puesta las metaTags */
  if (!to.meta.metaTags) return next();

  to.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // Usamos esto para rastrear qué metaetiquetas creamos para no interferir con otras.
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
})

export default router