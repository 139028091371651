<template>
  <h1 class="hidden">
    {{ datos.nombre }} Congreso Knotgroup
  </h1>
    <picture>
      <source
        class="bannerFoto__imgLogoHome--sub"
        srcset="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/ponentes/webp/imagen-cabecera-ponentes-knotgroup.webp"
        type="image/webp"
        alt="Cabecera Ponentes Congreso Knotgroup Los Orígenes"
        title="Cabecera Ponentes Congreso Knotgroup Los Orígenes"
        loading="lazy">
      <img
        class="bannerFoto__imgLogoHome--sub"
        src="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/ponentes/jpg/imagen-cabecera-ponentes-knotgroup.jpg"
        alt="Cabecera Ponentes Congreso Knotgroup Los Orígenes"
        title="Cabecera Ponentes Congreso Knotgroup Los Orígenes"
        loading="lazy"/>
    </picture>
  <div class="tituloPonentes">
    <h2>Ponentes</h2>
  </div>

  <div class="detallesSobrePonente">
    <div class="detallesSobrePonente__imagenYDetallesPonencia">
      <div class="imagenDelPonente">
        <picture class="imagenDelPonente__ponentePicture">
          <source 
          class="imagenDelPonente__ponentePicture--imgPonente"
          type="image/webp"
          :src="datos.webp"
          :alt="datos.alt"
          :title="datos.title"
          loading="lazy">
          <img 
          class="imagenDelPonente__ponentePicture--imgPonente" 
          :src="datos.img"
          :alt="datos.alt"
          :title="datos.title"
          loading="lazy"/>
        </picture>
      </div>
      <div class="detallesSobrePonente__imagenYDetallesPonencia--botones">
        <p @click="cambiarVistaDetalles()">
          <img
            src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/microsites/iconos/informacion.png"
            alt="Icono para ver los detalles de la ponencia knotgroup" />
          <i :class="{verDetalles : detallesNegrita}">DETALLE PONENCIA</i>
        </p>
        <hr>
        <p @click="cambiarVistaCV()">
          <img
            src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/microsites/iconos/informacion.png"
            alt="Icono para ver los detalles del cv knotgroup" />
          <i :class="{verCV : cvNegrita}">VER CV</i>
        </p>
        <br><br>
        <button @click="goToPonentes()"><img
            src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/microsites/iconos/flecha-volver-ponentes.png"
            alt="Ir a la página ponentes">
        </button>
      </div>
    </div>
    <div class="detallesSobrePonente__informacionPonente">
      <h2>{{datos.nombre}}</h2>
      <div class="detallesSobrePonente__informacionPonente--cv" :class="{cvReactivo : cv}">
        <ul>
          <li v-for="(parrafo, index) in datos.cv" :key="index">{{parrafo}}</li>
        </ul>
      </div>
      <div class="detallesSobrePonente__informacionPonente--detallesPonencia" :class="{detallesReactivo : detalles}">
        <p v-for="(ponencia, index) in datos.ponencias" :key="index" v-html="ponencia"></p>
      </div>

    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {

  name: "detalles ponente principal",
  data() {
    return {
      cv : true,
      detalles: false,
      cvNegrita: false,
      detallesNegrita: true,
      contador: 0,
    }
  }, 
  props: {
      datadetalleponente: Object,
  },
  methods: {
    cambiarVistaCV(){
      this.cv = false
      this.detalles=true
      this.cvNegrita = true
      this.detallesNegrita = false
    },
    cambiarVistaDetalles(){
      this.cv = true
      this.detalles=false
      this.cvNegrita = false
      this.detallesNegrita = true
    },
    goToPonentes(){
      this.$router.push('/ponentes');
    }
  },
    setup(props) {
      const datos = ref(props.datadetalleponente);
      return { datos };
    },
   
  }

</script>