<template>
  <nav id="menu" class="menu">
    <div class="menu__bloque">
      <div @click="hamburguesa()" class="menu__icono menu__icono--izquierda">
        <span class="activo1"></span>
        <span class="activo2"></span>
        <span class="activo3"></span>  
      </div>
      <span class="menu__bloque--span">MENU</span>
    </div>
    
    <div class="menu__bloque">
      <span class="menu__bloque--span">PRESENTACIÓN</span>
      <div @click="cruz()" class="menu__cruz menu__icono--derecha">
        <span class="cruz_linea_1 activo_cruz_1"></span>
        <span class="cruz_linea_2 activo_cruz_2"></span>
      </div>
    </div>
    
  </nav>

  <div class="menu__desplegable_izquierda">
    <ul class="informacion">
      <li class="informacion__titulo">
        <a title="información Knotgroup" href="/informacion" class="informacion__titulo--a">INFORMACIÓN</a>
      </li>
      <li class="informacion__li">
        <a title="Orígenes Knotgroup" href="/informacion" class="informacion__a">Los Orígenes</a>
      </li>
      <li class="informacion__li">
        <a title="comité organizador Knotgroup" href="/informacion/#ancla_comite" class="informacion__a"
          >Comités</a
        >
      </li>
      <li class="informacion__li">
        <a title="sede Knotgroup" href="/informacion/#ancla_sede" class="informacion__a">Sede</a>
      </li>
      <li class="informacion__li">
        <a title="Vigo Knotgroup" href="/informacion/#ancla_vigo" class="informacion__a">Vigo</a>
      </li>
      <li class="informacion__li">
        <a title="cena Knotgroup" href="/informacion/#ancla_cena" class="informacion__a">Cena</a>
      </li>
      <li class="informacion__li">
        <a title="descuentos Knotgroup" href="/informacion/#ancla_descuentos" class="informacion__a">Descuentos</a>
      </li>
    </ul>
    <ul class="informacion__segundo">
      <li class="informacion__li--segundo">
        <a title="programa Knotgroup" href="/programa" class="informacion__a informacion__a--segundo"
          >PROGRAMA</a
        >
      </li>
      <li class="informacion__li--segundo">
        <a title="ponentes Knotgroup" href="/ponentes" class="informacion__a informacion__a--segundo"
          >PONENTES</a
        >
      </li>
      <li class="informacion__li--segundo">
        <a title="talleres Knotgroup" href="/talleres" class="informacion__a informacion__a--segundo"
          >TALLERES</a
        >
      </li>
    </ul>
  </div>

  <div class="menu__desplegable_derecha">
    <div class="menu__desplegable_derecha__contenidos">
      <picture>
        <source
        class="informacion__logo"
        srcset="https://congresoknotgroup.inspiriadental.com/imagenes/home/webp/logo-home-knotgroup.webp"
        type="image/webp"
        >
        <img
        class="informacion__logo"
        src="https://congresoknotgroup.inspiriadental.com/imagenes/home/png/logo-home-knotgroup.png"
        alt="Logo Congreso Knotgroup III Los Orígenes"
        title="Logo Congreso Knotgroup III Los Orígenes"
        loading="lazy"
      />
    </picture>
      <p class="menu__desplegable_derecha__titulo">PRESENTACIÓN</p>
      <p class="menu__desplegable_derecha__texto">
        Un Congreso único en el que descubriremos <strong>Los Orígenes de Knotgroup Dental Corporation</strong>.
        Hablaremos de la relación de la Odontología con otras especialidades médicas y, por supuesto,
        nos asomaremos a otras áreas esenciales de los Orígenes de todas las Clínicas Knotgroup:
        Odontología, Medicina, Tecnología, Innovación y Equipos son nuestros principios, nuestra Razón de Ser. ¿Te lo vas a perder?
      </p>
      <video class="menu__desplegable_derecha__video" poster="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/congreso/fotos/portada-origenes.png" controls loading="lazy">
        <source src="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/videos/mp4/knotgroup-video-presentacion.mp4">
      </video>
    </div>
  </div>
</template>


<script>
export default {
  name: "menu Home",

  methods: {
    hamburguesa() {
      var menu__linea1 = document.querySelector(".activo1");
      var menu__linea2 = document.querySelector(".activo2");
      var menu__linea3 = document.querySelector(".activo3");
      var cruz__linea_1 = document.querySelector(".activo_cruz_1");
      var cruz__linea_2 = document.querySelector(".activo_cruz_2");
      var menu__desplegable_izquierda = document.querySelector(".menu__desplegable_izquierda");
      var menu__desplegable_derecha = document.querySelector(".menu__desplegable_derecha");

      menu__linea1.classList.toggle("menu__linea--1");
      menu__linea2.classList.toggle("menu__linea--2");
      menu__linea3.classList.toggle("menu__linea--3");

   if (!menu__desplegable_izquierda.classList.contains("menu__desplegable_izquierda--activo")) {
        menu__desplegable_izquierda.classList.toggle("menu__desplegable_izquierda--activo");
        if(menu__desplegable_derecha.classList.contains("menu__desplegable_derecha--activo")){
          menu__desplegable_derecha.classList.toggle("menu__desplegable_derecha--activo");
          cruz__linea_1.classList.toggle("cruz_linea_1");
          cruz__linea_2.classList.toggle("cruz_linea_2");
          cruz__linea_1.classList.toggle("equis_linea_1");
          cruz__linea_2.classList.toggle("equis_linea_2");
        }
      } else {
        menu__desplegable_izquierda.classList.toggle("menu__desplegable_izquierda--activo");
      }
    },

    cruz() {
      var cruz__linea_1 = document.querySelector(".activo_cruz_1");
      var cruz__linea_2 = document.querySelector(".activo_cruz_2");
      var menu__linea1 = document.querySelector(".activo1");
      var menu__linea2 = document.querySelector(".activo2");
      var menu__linea3 = document.querySelector(".activo3");
      var menu__desplegable_derecha = document.querySelector(".menu__desplegable_derecha");
      var menu__desplegable_izquierda = document.querySelector(".menu__desplegable_izquierda");

      cruz__linea_1.classList.toggle("cruz_linea_1");
      cruz__linea_2.classList.toggle("cruz_linea_2");
      cruz__linea_1.classList.toggle("equis_linea_1");
      cruz__linea_2.classList.toggle("equis_linea_2");

      if (!menu__desplegable_derecha.classList.contains("menu__desplegable_derecha--activo")) {
        menu__desplegable_derecha.classList.toggle("menu__desplegable_derecha--activo");
        if(menu__desplegable_izquierda.classList.contains("menu__desplegable_izquierda--activo")){
          menu__desplegable_izquierda.classList.toggle("menu__desplegable_izquierda--activo");
          menu__linea1.classList.toggle("menu__linea--1");
          menu__linea2.classList.toggle("menu__linea--2");
          menu__linea3.classList.toggle("menu__linea--3");
        }
      } else {
        menu__desplegable_derecha.classList.toggle("menu__desplegable_derecha--activo");
      }
      
    },
  },
};
</script>