<template>
  <img
    class="bannerFoto"
    src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/cabeceraponentes.jpg"
    alt=""
  />
  <div class="politicaPrivacidad">
    <h1 class="politicaPrivacidad__titulo">POLÍTICA DE PRIVACIDAD</h1>
    
    <h2 class="hidden">Congreso Knotgroup Los Orígenes</h2>

    <h3>1) Introducción:</h3>
    <p>
      En KNOT GROUP DENTAL CORPORATION, SL (en adelante, KNOTGROUP) la seguridad
      y la privacidad de los datos personales de nuestros usuarios son y serán
      siempre de enorme importancia para nosotros. Por este motivo, hemos
      desarrollado la presente Política de Privacidad, que recoge de forma clara
      y transparente cómo y para qué recopilamos, almacenamos, compartimos y
      utilizamos sus datos personales y poder darle a conocer los derechos a los
      que tiene acceso en el tratamiento de sus datos y cómo ejercerlos. Esta
      Política de Privacidad aplica a todos los servicios y productos ofrecidos
      por KNOTGROUP, a través de cualquier canal. Para saber más sobre la
      política de cookies de los sitios web de KNOTGROUP, debe acceder al
      apartado <a href="/cookies">Cookies</a>.
    </p>
    <h3>2) Responsable del Tratamiento:</h3>
    <p>
      KNOTGROUP es una sociedad con sede en España (para más información acceder
      al apartado Acerca de), y es Responsable del Tratamiento de todos los
      datos personales recogidos a través del presente dominio web
      (www.knotgroupdentalcorporation.com). En caso de que exista cualquier otro
      co-Responsable del Tratamiento en la cesión de determinados datos por
      parte del usuario, se indicará expresamente la identidad del mismo en
      aquellos apartados en los que deba aplicarse.
    </p>
    <h3>3) Categorías de datos tratados:</h3>
    <p>
      A continuación, se recogen todas las categorías de datos tratados por
      KNOTGROUP:
    </p>
    <h4>3.1) Datos tratados de usuarios de nuestra página:</h4>
    <ul>
      <li>
        – Datos de miembros: Responde a aquellos usuarios que ya forman parte de
        KNOTGROUP. Los datos recogidos de estos usuarios son: nombre, apellidos,
        email, teléfono, clínica dental (lugar de trabajo), usuario y contraseña
        de KNOTGROUP MEMBERS, datos de facturación (razón social, número de
        identificación fiscal, dirección social) y los datos bancarios
        necesarios para el cobro y gestión de los servicios prestados por
        KNOTGROUP.
      </li>
      <li>
        – Datos de profesionales: Responde a aquellos usuarios interesados en
        saber más sobre los servicios prestados por KNOTGROUP. Los datos
        recogidos de estos usuarios son: nombre, apellidos, email, teléfono,
        clínica dental (lugar de trabajo).
      </li>
      <li>
        – Datos de candidatos: Responde a aquellos usuarios que dejan sus datos
        en nuestra bolsa de empleo. Los datos recogidos de estos usuarios son:
        nombre, apellidos, email, teléfono y cualesquiera otros que incluya y
        nos remita el propio usuario en su currículum vitae.
      </li>
      <li>
        – Datos de pacientes: Responde a aquellos usuarios que desean asistir u
        obtener información sobre alguna de las clínicas asociadas a KNOTGROUP.
        En KNOTGROUP no recogemos ni tratamos datos especialmente protegidos
        (tal y como se definen conforme al art. 9.1 del Reglamento (UE)
        2016/679, de Protección de Datos). Los únicos datos recogidos de estos
        usuarios son: nombre, apellidos, email, teléfono, país, ciudad, clínica
        dental a la que desea asistir y especialidad clínica sobre la que
        solicita información.
      </li>
      <li>
        -Datos de asistentes a congresos o jornadas profesionales: Responde a
        aquellos profesionales del sector que quieren asistir a los congresos o
        jornadas organizadas por KNOTGROUP. Los datos recogidos de estos
        usuarios son: nombre, apellidos, email, teléfono, clínica dental (lugar
        de trabajo), datos de facturación (razón social, número de
        identificación fiscal, dirección social) y los datos bancarios
        necesarios para el cobro y gestión de los servicios prestados por
        KNOTGROUP.
      </li>
    </ul>
    <h4>
      3.2) Datos tratados comunes a todos los tipos de usuarios de KNOTGROUP (se
      incluyen los datos recogidos durante la navegación del usuario en
      cualquier web o aplicación de KNOTGROUP):
    </h4>
    <ul>
      <li>– Datos de uso:</li>
      <ul>
        <li>
          + Incluye información acerca de sus interacciones con nuestros
          servicios, como las búsquedas en web, el historial de navegación y
          visualización de contenidos. Pueden incluirse también datos del uso
          por su parte de aplicaciones de terceros en relación con nuestros
          servicios.
        </li>
        <li>
          + Inferencias acerca de sus intereses y preferencias a la luz del uso
          que hace de nuestros servicios.
        </li>
        <li>
          + Datos técnicos: entre ellos, información de URL; identificadores
          online, incluyendo datos de cookies y direcciones IP; información
          sobre los tipos de dispositivos que utiliza, como los identificadores
          de dispositivo único, el tipo de conexión a la red (p. ej., wifi, 3G,
          LTE, Bluetooth), el rendimiento de la red, el dispositivo y el
          proveedor, el tipo de navegador, el idioma, el sistema operativo; su
          ubicación no exacta, que puede deducirse o inferirse de ciertos datos
          técnicos (p. ej., la dirección IP, la configuración del idioma de su
          dispositivo o la moneda de pago).
        </li>
      </ul>
      <li>– Datos provenientes de terceras entidades colaboradoras:</li>
      <ul>
        <li>
          + Colaboradores de servicios técnicos: utilizamos colaboradores de
          servicios técnicos como Google Analytics, que nos proporcionan ciertos
          datos, como la asignación de direcciones IP a información acerca de
          una ubicación no exacta (ej. ciudad y estado).
        </li>
      </ul>
      <li>
        – Datos de encuestas y concursos: comprende todos los datos solicitados
        en la encuesta o concurso correspondiente.
      </li>
    </ul>
    <h3>4) Finalidad del tratamiento:</h3>
    <p>
      En KNOTGROUP tratamos los datos proporcionados por el usuario únicamente
      para los siguientes fines legítimos y conforme a las bases legales
      correspondientes:
    </p>
    <table>
      <tr>
        <th>Finalidad del tratamiento</th>
        <th>Bases legales</th>
        <th>
          Categoría de datos personales usados por KNOTGORUP para la finalidad
          del tratamiento
        </th>
      </tr>
      <tr>
        <td>
          Para prestar cualquiera de los servicios ofrecidos por KNOTGROUP a sus
          miembros.
        </td>
        <td>– Cumplimiento de un contrato – Consentimiento</td>
        <td>– Datos de miembros</td>
      </tr>
      <tr>
        <td>Para ofrecerle información solicitada sobre KNOTGROUP</td>
        <td>– Consentimiento</td>
        <td>– Datos de profesionales</td>
      </tr>
      <tr>
        <td>
          Para ofrecerle información solicitada sobre cualquiera de las clínicas
          asociadas a KNOTGROUP
        </td>
        <td>– Consentimiento</td>
        <td>– Datos de pacientes</td>
      </tr>
      <tr>
        <td>
          Para procesar solicitudes de empleo conforme a los datos recibidos en
          nuestra bolsa de trabajo
        </td>
        <td>– Consentimiento</td>
        <td>– Datos de candidatos</td>
      </tr>
      <tr>
        <td>Con fines de marketing, promoción y publicidad</td>
        <td>– Interés legítimo – Consentimiento</td>
        <td>
          – Datos de miembros – Datos de profesionales – Datos de pacientes –
          Datos de candidatos – Datos provenientes de terceras entidades
          colaboradoras –Datos de asistente a congreso o jornada
        </td>
      </tr>
      <tr>
        <td>Para procesar y facturar pagos y cobros</td>
        <td>
          – Cumplimiento de un contrato – Consentimiento – Interés legítimo
        </td>
        <td>– Datos de miembros –Datos de asistente a congreso o jornada</td>
      </tr>
      <tr>
        <td>
          Para comprender, diagnosticar, resolver y solucionar problemas de
          cualquiera de nuestros servicios.
        </td>
        <td>– Cumplimiento de un contrato – Interés legítimo</td>
        <td>
          – Datos de miembros – Datos de profesionales – Datos de pacientes –
          Datos de candidatos – Datos de uso – Datos de asistente a congreso o
          jornada
        </td>
      </tr>
      <tr>
        <td>
          Para evaluar y desarrollar nuevas funciones, tecnologías y mejoras de
          cualquiera de nuestros servicios.
        </td>
        <td>– Interés legítimo – Consentimiento</td>
        <td>– Datos de uso</td>
      </tr>
      <tr>
        <td>
          Para cumplir obligaciones legales y requerimientos de las autoridades
          competentes.
        </td>
        <td>
          – Interés legítimo – Cumplimiento de obligaciones legales –
          Cumplimiento de un contrato
        </td>
        <td>
          – Datos de miembros – Datos de profesionales – Datos de pacientes –
          Datos de candidatos – Datos provenientes de terceras entidades
          colaboradoras – Datos de uso – Datos de encuestas y concursos –Datos
          de asistente a congreso o jornada
        </td>
      </tr>
      <tr>
        <td>
          Para iniciar o ejercitar actuaciones legales u oponerse a ellas.
        </td>
        <td>– Interés legítimo</td>
        <td>
          – Datos de miembros – Datos de profesionales – Datos de pacientes –
          Datos de candidatos – Datos provenientes de terceras entidades
          colaboradoras – Datos de uso – Datos de encuestas y concursos – Datos
          de asistente a congreso o jornada
        </td>
      </tr>
      <tr>
        <td>Para realizar concursos y encuestas.</td>
        <td>– Cumplimiento de un contrato – Consentimiento</td>
        <td>– Datos de encuestas y concursos</td>
      </tr>
      <tr>
        <td>
          Para detectar fraudes, en particular pagos fraudulentos y usos
          fraudulentos de cualquiera de nuestros servicios.
        </td>
        <td>
          – Interés legítimo – Cumplimiento de obligaciones legales –
          Cumplimiento de un contrato
        </td>
        <td>
          – Datos de miembros – Datos de profesionales – Datos de pacientes –
          Datos de candidatos – Datos provenientes de terceras entidades
          colaboradoras – Datos de uso – Datos de encuestas y concursos – Datos
          de asistente a congreso o jornada
        </td>
      </tr>
      <tr>
        <td>
          Para fines internos de planificación, información y estimaciones del
          negocio.
        </td>
        <td>– Interés legítimo</td>
        <td>– Datos de uso</td>
      </tr>
      <tr>
        <td>
          Para prestar los servicios ofrecidos por KNOTGROUP a los asistentes a
          Congresos o Jornadas profesionales.
        </td>
        <td>– Cumplimiento de un contrato – Consentimiento</td>
        <td>– Datos de asistente a congreso o jornada.</td>
      </tr>
    </table>
    <p>
      Todas las bases legales contenidas en este apartado, se contemplan tal y
      como vienen definidas en el Reglamento General (UE) 2016/679 de Protección
      de Datos. Si desea más información sobre los criterios de evaluación
      utilizados por KNOTGROUP para fundamentar el tratamiento de los datos con
      base al interés legítimo, no dude en contactarnos a través de cualquiera
      de los medios de contacto proporcionados en el punto 5. Derechos del
      usuario en el tratamiento de sus datos.
    </p>
    <h3>5) Duración del Tratamiento:</h3>
    <p>
      Todos los datos personales proporcionados por el usuario se conservarán,
      conforme lo estipulado en la presente Política de Privacidad, mientras no
      se solicite su supresión por parte del interesado, sin que ello afecte a
      la licitud del tratamiento basado en el consentimiento previo a su
      retirada. Todo ello, siempre de acuerdo a las finalidades legítimas y
      esenciales en cada caso, como pueden ser mantener la prestación de
      cualquiera de los servicios prestados por KNOTGROUP y requeridos por el
      usuario, adoptar decisiones empresariales basadas en datos o cumplir
      nuestras obligaciones legales y resolver controversias.
    </p>
    <p>
      La previsión recogida en el párrafo anterior no se aplicará en el caso de
      que KNOTGROUP esté legalmente facultada u obligada a mantener ciertos
      datos personales, como puede ser en el caso de que existan pagos u
      obligaciones contractuales pendientes de ser cumplidas por el usuario.
    </p>
    <h3>6) Derechos del usuario en el tratamiento de sus datos:</h3>
    <p>
      KNOTGROUP informa a todos sus usuarios de la posibilidad de ejercitar sus
      derechos de acceso, rectificación o supresión, o a la limitación del
      tratamiento de sus datos, directamente oponerse al tratamiento o ejercer
      el derecho a la portabilidad de éstos, escribiéndonos al correo
      electrónico: info@knotgorupdentalcorporation.com o por correo ordinario a
      la dirección C/ Mayor de Fontenebro 238, 28400, Madrid, España. En dicha
      solicitud deberá proporcionar, en cualquier caso, identificación
      suficiente que nos permita reconocer su condición de interesado.
    </p>
    <p>
      Por último, ante cualquier eventualidad, le informamos de su derecho de
      presentar una reclamación ante la Agencia Española de Protección de Datos.
    </p>
    <h3>7) Encargados del Tratamiento:</h3>
    <ul>
      <li>
        <h3>7.1) Clínicas asociadas a KNOTGROUP:</h3>
        <p>
          Con el objeto de ofrecerle información solicitada sobre cualquiera de
          las clínicas asociadas a KNOTGROUP, podremos remitir sus Datos de
          Paciente a cualquiera de las clínicas que más se ajuste a sus
          necesidades (atendiendo, sobre todo, en primer lugar, a aquellas que
          se encuentren en su localización más cercana). Para más información
          sobre la identidad y contacto de todas las clínicas asociadas a
          KNOTGORUP, puede visitar el apartado
          <a href="">https:// knotgroupdentalcorporation.com/centros/</a>
        </p>
      </li>
      <li>
        <h3>7.2) Servicios web:</h3>
        <p>
          El presente sitio web trabaja con Google Analytics, lo que significa
          que determinados datos, como su dirección IP o información recogida
          por nuestras cookies (ver <a href="/cookies">Cookies</a>), pueden ser
          recogidos por Google a través de nuestros sitios web. Además,
          KNOTGROUP cuenta con infraestructura virtual según un modelo de
          “computación en la nube” a través de Amazon Web Services. Ambos
          servicios se encuentran al amparo del acuerdo EU-US Privacy Shield
          (información disponible en:<a
            href="https://www.aepd.es/sites/default/files/2019-09/guia-acerca-del-escudo-de-privacidad.pdf"
            target="_blank"
            rel="noopener"
          >
            https://www.aepd.es/sites/default/files/2019-09/guia-acerca-del-escudo-de-privacidad.pdf</a
          >). Asimismo, KNOTGROUP también cuenta con otros servicios de
          infraestructura virtual de “computación en la nube”, prestados por el
          Grupo Zoho, entidad que cuenta con cláusulas contractuales tipo
          adheridas a las normas de Protección de Datos de la UE (información de
          dichas cláusulas disponible en el siguiente:<a
            href="https://www.zoho.com/privacy/dpa/DPA_zoho_com.pdf"
            target="_blank"
            rel="noopener"
          >
          enlace</a
          >. Aquí puede encontrar más información de la identidad y localización
          de las entidades del Grupo Zoho:<a
            href="https://www.zoho.com/es-xl/privacy/zoho-group.html"
            target="_blank"
            rel="noopener"
          >
            https://www.zoho.com/es-xl/privacy/zoho-group.html</a
          >).
        </p>
      </li>
      <li>
        <h3>7.3) Otras empresas del Grupo KNOTGROUP:</h3>
        <p>
          Podremos compartir los datos personales de nuestros usuarios con otras
          empresas del grupo KNOTGROUP para llevar a cabo nuestras actividades
          cotidianas y para poder mantener y ofrecerle nuestros Servicios.
        </p>
        <p>
          Las mencionadas sociedades son INSPIRIA DENTAL, S.L., sociedad con
          sede en España y número de identificación fiscal B88302492, ALEBAT
          EDUCATION, SL, sociedad con sede en España y número de identificación
          fiscal B87437018 y ALEBAT MANAGEMENT CONSULTING, sociedad con sede en
          España y número de identificación fiscal B84571231.
        </p>
      </li>
      <li>
        <h3>7.4) Otros colaboradores</h3>
        <p>
          Para el caso de que Knot Group colabore con otras empresas ajenas al
          grupo, los datos de asistentes a congresos y jornadas profesionales
          podrán ser cedidos para cumplir con los acuerdos establecidos entre
          entidades con la finalidad de que un tercero pueda hacerle llegar
          información comercial de sus productos o servicios.
        </p>
        <p>
          En caso de que exista cualquier otro co-Responsable del Tratamiento en
          la cesión de determinados datos por parte del usuario, se indicará
          expresamente la identidad del mismo en aquellos apartados en los que
          deba aplicarse.
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
</script>