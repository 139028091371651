<template>
    <h1 class="hidden">Congreso Knotgroup</h1>
  <img
    class="bannerFoto"
    src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/congreso/fotos/informacion/png/cabeceratalleres.png"
    alt=""
  />

    <section class="sectionGeneral">
    <div class="tituloTalleres">
        <h2>SALA TALLERES</h2>
         <picture>
            <img src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/congreso/iconos/IPD.png" alt="" title="">
        </picture>
    </div>
    <h3 class="hidden">Práctica odontológica</h3>
    <h3 class="hidden">Salud Periodontal</h3>
    <h3 class="hidden">Fotografía Dental</h3>
    <h3 class="hidden">Odontología</h3>
        <table class="tablaTalleres">
            <tr v-for="(taller,index) in datos" :key="index" class="tablaTalleres__filas">
                <td class="tablaTalleres__filas__horas" :id="`H${index}`">
                    <p>{{taller.fecha_inicio}}-{{taller.fecha_fin}}</p>
                    <div v-if="taller.tipo===1">
                        <button v-if="taller.completo" class="botonCompleto">COMPLETO</button>
                        <button v-else class="botonApuntate" @click="cambioFormulario(index)">APÚNTATE</button>
                    </div>
                </td>
                <td class="tablaTalleres__filas__eventos" :id="`E${index}`">
                    <div class="eventosTexto">
                        <p>{{taller.titulo}}</p>
                        <p v-if="taller.tipo===1"><b>{{taller.ponente}}</b></p>
                        <!-- <p v-if="taller.tipo===1">DESCRIPCIÓN {{taller.id}}</p> -->
                        <p v-if="taller.tipo===1">{{taller.descripcion}}</p>
                    </div>
                    <div class="eventosIcono">
                        <picture>
                            <img :src="taller.icono" alt="">
                        </picture>
                    </div>
                    
                </td>
               
                <td class="tablaTalleres__filas__botonPc" :id="`BTNS${index}`">
                    <div class="contenedorBoton" v-if="taller.tipo===1">
                        <button v-if="taller.completo" class="botonCompleto">COMPLETO</button>
                        <button v-else class="botonApuntate" @click="cambioFormulario(index)" >APÚNTATE</button>
                    </div>
                </td>
                <td :id="'F'+index" class="tablaTalleres__filas__formulario" :class="`F${index}`" style="display:none">

                    <form action='https://forms.zohopublic.com/inspiriadental/form/congresokg2023talleres/formperma/OOCfEbzKNJ75XwksjQXEsaSeHjGFKR5mYPZYiGd9LJM/htmlRecords/submit' name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>
                        <input type="hidden" name="zf_referrer_name" value=""><!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
                        <input type="hidden" name="zf_redirect_url" value=""><!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
                        <input type="hidden" name="zc_gad" value=""><!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
                        <input required class="sinFondo" type="text" name="SingleLine" fieldType=1 maxlength="255" placeholder="Nombre y apellidos">
                        <input required class="sinFondo" type="email" name="SingleLine1" fieldType=1 maxlength="255" placeholder="Email">
                        <input required class="sinFondo" type="text" name="SingleLine2" fieldType=1 maxlength="255" placeholder="Clínica/Empresa">
                        <input  type="hidden" name="SingleLine3" :value="taller.titulo" fieldType=1 maxlength="255" placeholder="" />
                        <a @click="ajaxEnvioFormulario(taller.id)"><input type="submit" value="Apuntarme"></a>

                    </form>
                </td>
                <td class="tablaTalleres__filas__x" @click="cambioFormulario(index)" style="display: none;" :id="`X${index}`">
                   <picture>
                        <img src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/congreso/iconos/icono-cerrar-negro.png" alt="" title="">
                   </picture> 
                </td>
            </tr>            
        </table>
        <h4 class="hidden">Didier delmas</h4>
        <h4 class="hidden">Oriol Cantó</h4>
        <h4 class="hidden">Alejandro Otero</h4>
        <h4 class="hidden">Pasquín</h4>
        <h5 class="hidden">IPD</h5>
        <h5 class="hidden">IPD Dental Group</h5>
        <h5 class="hidden">EMS</h5>
        <h5 class="hidden">EMS Dental</h5>
    </section>
</template>

<script>

export default {
    name:"talleres principal",
    props: {
        dataTalleres: Object,
    },
    inject : ['urlPeticion'],
    setup(props) {
        const datos = props.dataTalleres;
        return { datos };
    },
    data(){
        return{
            arrayTitulos : [],
            linkInscribirse: `${this.urlPeticion}/api/inscribeTaller/`

        }
    },
    created (){
        for(let i=0;i<this.datos.length;i++){
            if(this.datos[i].tipo==1){
                this.arrayTitulos.push(this.datos[i]);
            }
        }
        return this.arrayTitulos
    },
    methods : {
        cambioFormulario(i){
            /*************************************/
            const hora = document.getElementById(`H${i}`);
            const evento = document.getElementById(`E${i}`);
            const botones = document.getElementById(`BTNS${i}`);
            /*************************************/
            const x = document.getElementById(`X${i}`);
            const formulario = document.getElementsByClassName('tablaTalleres__filas__formulario');
            /*************************************/
            const mediaquery_movil = window.matchMedia("(min-width: 1024px)and (orientation:landscape)");

            if(formulario[i].style.display=='none'){
                formulario[i].style.display = 'flex';
                x.style.display='flex';
                hora.style.display ='none';
                evento.style.display ='none';
                botones.style.display ='none';
            }else{
                formulario[i].style.display = 'none';
                x.style.display='none';
                hora.style.display ='flex';
                evento.style.display ='flex';
                if (mediaquery_movil.matches){
                    botones.style.display ='flex';
                }
            }
        },
        agregarParticipante(){
            const datos = document.getElementById('taller');
            const seleccionado = datos.options[datos.selectedIndex]
            console.log(seleccionado);
        },
        ajaxEnvioFormulario(index){
            const http = new XMLHttpRequest();
            const url = this.linkInscribirse+index;
            
            http.open("POST", url)
            http.send();
        }
    }
}
</script>
